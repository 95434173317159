import { gsap, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(ScrollTrigger)

export default (animated = false) => ({
  init() {
    const widthFinderEl = this.$refs.widthFinder
    const lineEl = this.$refs.line
    if (!(widthFinderEl && lineEl)) return

    const ctx = this
    this.setLineWidth()

    const resizeObserver = new ResizeObserver(() => ctx.setLineWidth())
    resizeObserver.observe(this.$el)

    if (!animated) return
    gsap.to(lineEl, {
      scrollTrigger: {
        trigger: this.$el,
        start: "center center",
        end: "center center",
      },
      scaleX: 1,
      duration: 1,
    })
  },
  setLineWidth() {
    const widthFinderEl = this.$refs.widthFinder
    const lineEl = this.$refs.line
    if (!(widthFinderEl && lineEl)) return

    lineEl.style.width = widthFinderEl.offsetWidth * 0.75 + "px"
  },
})
