import { register as registerSwiper } from "swiper/element/bundle"

registerSwiper()

export default () => ({
  init() {
    const swiperEl = this.$el

    const swiperParams = {
      pagination: {
        el: this.$refs.pagination,
        bulletClass: "bullet-pagination__item",
        bulletActiveClass: "bullet-pagination__item--active",
        clickable: true,
      },
      loop: true,
      effect: "fade",
      crossFade: true,
      autoplay: {
        delay: 7000,
      },
    }
    Object.assign(swiperEl, swiperParams)

    swiperEl.initialize()
  },
})
